<template>
  <v-app>
    <v-main>
      <v-card
        width="500px"
        class="pa-4 mt-10 ma-auto"
      >
        <v-card-title>Login</v-card-title>
        <v-card-text>
          <v-form @keyup.native.enter="login">
            <v-text-field
              v-model="email"
              label="adresse email"
              required
            />

            <v-text-field
              v-model="password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              name="input-10-1"
              label="mot de passe"
              counter
              @click:append="show = !show"
            />
          </v-form>
        </v-card-text>
        <v-divider />
        <v-alert
          v-if="error"
          dense
          text
          type="error"
        >{{error}}</v-alert>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            large
            @click="login"
          >
            Login
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-main>
  </v-app>
</template>

<script>
  export default {
    name: 'Login',
    data () {
      return {
        email: '',
        password: '',
        show: false,
        error: null,
      }
    },
    methods: {
      login () {
        this.error = null
        this.$login(this.email, this.password)
          .catch(e => {
            if (e.message === 'GraphQL error: Invalid user credentials.') {
              this.error = 'Utilisateur ou mot de passe invalide'
            } else if (e.message === 'GraphQL error: User suspended.') {
              this.error = 'Utilisateur suspendu pour des raisons de sécurité'
            } else {
              this.error = e.message
            }
          });
      },
    },
  }
</script>

<style scoped>

</style>
